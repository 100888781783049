import { Button, Cascader, CascaderOption, DatePicker, DatePickerRef, Dialog, Form, Input, Picker, Radio, Selector, Space, Toast } from "antd-mobile";
import dayjs from "dayjs";
import { RefObject, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getDictList, getRegionByPid, IDictDataOption } from "../../apis/common";
import { getCustomInfo, ICustomerInfoForm, updateCustomInfo } from "../../apis/custom";

import './info.scss';

import bgImg from '../../assets/info/bg.svg';
import userImg from '../../assets/icons/info/user.svg';
import birthdayImg from '../../assets/icons/info/birthday.svg';
import mobileImg from '../../assets/icons/info/mobile.svg';
import positionImg from '../../assets/icons/info/position.svg';
import sexImg from '../../assets/icons/info/sex.svg';

const locationValidator = async (_rule: any, value?: string[]) => {
    if (!value) return Promise.reject('请选择所在区域');
    if (value.length < 4) return Promise.reject('请完整填写所在地区');
    return Promise.resolve();
}

const onFinishFailed = () => {
    Toast.show({
        icon: 'fail',
        content: '资料填写有误，请检查',
        duration: 2000,
        maskClickable: false
    });
}

function InfoPage() {
    document.title = '完善信息';

    const navigate = useNavigate();

    const [form] = Form.useForm<ICustomerInfoForm>();
    const onFinish = () => {
        Toast.show({
            icon: 'loading',
            content: '保存中',
            duration: 0,
            maskClickable: false
        })
        const values = form.getFieldsValue(true);
        let newForm: ICustomerInfoForm = JSON.parse(JSON.stringify(values));
        newForm.duty_id = Number(values.duty_id[0]);
        // newForm.scale_id = values.scale_id[0] ? Number(values.scale_id[0]) : undefined;
        // newForm.business_id = values.business_id[0] ? Number(values.business_id[0]) : undefined;
        newForm.birthday = values.birthday ? dayjs(values.birthday).format('YYYY-MM-DD') : undefined;
        // newForm.province_id = values.location[0] ? Number(values.location[0]) : undefined;
        // newForm.city_id = values.location[1] ? Number(values.location[1]) : undefined;
        // newForm.area_id = values.location[2] ? Number(values.location[2]) : undefined;
        // newForm.street_id = values.location[3] ? Number(values.location[3]) : undefined;
        // newForm.source_type = values.source_type ? values.source_type[0] : undefined;
        updateCustomInfo(newForm).then((res) => {
            Toast.clear();
            Dialog.confirm({
                content: `${res.msg}，立即前往会员开通页面`,
                onConfirm: () => navigate('/vip/', { replace: true }),
                onCancel: () => navigate(-1),
            })
        })
    }

    const [dutyList, setDutyList] = useState<IDictDataOption[]>();
    const [scaleList, setScaleList] = useState<IDictDataOption[]>();
    const [businessList, setBusinessList] = useState<IDictDataOption[]>();
    const [sourceTypeList, setSourceTypeList] = useState<IDictDataOption[]>();

    // 初始化
    useEffect(() => {
        Toast.show({
            icon: 'loading',
            content: '加载中',
            duration: 0,
            maskClickable: false
        })

        Promise.all([
            getDictList(['duty', 'scale', 'business', 'source_type']).then((res) => {
                setDutyList(res.data.duty?.options);
                setScaleList(res.data.scale?.options);
                setBusinessList(res.data.business?.options);
                setSourceTypeList(res.data.source_type?.options)
            }),

            getCustomInfo().then((res) => {
                let rawData = res.data.detail;
                if (rawData) {
                    let data = JSON.parse(JSON.stringify(rawData));
                    data.duty_id = rawData.duty_id ? [rawData.duty_id] : undefined;
                    data.scale_id = data.scale_id ? [String(rawData.scale_id)] : undefined;
                    data.business_id = rawData.business_id ? [String(rawData.business_id)] : undefined;
                    data.birthday = rawData.birthday ? dayjs(rawData.birthday).toDate() : undefined;
                    data.source_type = rawData.source_type ? [rawData.source_type] : undefined;

                    if (rawData.province_id && rawData.city_id && rawData.area_id && rawData.scale_id) {
                        data.location = [String(rawData.province_id), String(rawData.city_id), String(rawData.area_id), String(rawData.scale_id)];
                        fetchOptionsForValue(String(rawData.province_id), 1);
                        fetchOptionsForValue(String(rawData.city_id), 2);
                        fetchOptionsForValue(String(rawData.area_id), 3);
                        fetchOptionsForValue(String(rawData.scale_id), 4);
                    }

                    form.setFieldsValue(data);
                }
            }),
        ]).finally(() => Toast.clear());

    }, []);

    // 省市区选择器
    const [valueToOptions, setValueToOptions] = useState(
        {} as Record<string, CascaderOption[] | null>
    );
    const options = useMemo<CascaderOption[]>(() => {
        function generate(v: string): CascaderOption[] | undefined {
            const options = valueToOptions[v]
            if (options === null) {
                return undefined
            }
            if (options === undefined) {
                return Cascader.optionSkeleton
            }
            return options.map(option => ({
                ...option,
                children: generate(option.value),
            }))
        }
        return generate('') ?? []
    }, [valueToOptions]);
    async function fetchOptionsForValue(v: string, level: number) {
        if (v in valueToOptions) return
        if (level >= 4) {
            setValueToOptions(prev => ({
                ...prev,
                [v]: null,
            }))
            return
        }
        const { data } = await getRegionByPid({ pid: Number(v) });
        const options =
            data && data.items.length > 0
                ? data.items.map(entry => ({
                    value: String(entry.id),
                    label: entry.name,
                }))
                : null
        setValueToOptions(prev => ({
            ...prev,
            [v]: options,
        }))
    }
    useEffect(() => {
        fetchOptionsForValue('', 0);
    }, [])

    return (
        <div className="info">
            <div className="bg">
                <img className="bg__img" src={bgImg} />
                <div className="bg__text">
                    <div className="bg__text__title">完善个人信息</div>
                    <div className="bg__text__sub">注册会员，尊享各种特权服务</div>
                </div>
            </div>
            <div className="form-box">
                <Form
                    className="form"
                    form={form}
                    name='form'
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    footer={
                        <Button block type='submit' color='primary' size='large'>
                            提交
                        </Button>
                    }
                >
                    <Form.Item name='contact_name' label={
                        <><img className="label" src={userImg}></img>姓名</>
                    } rules={[{ required: true }]}>
                        <Input placeholder='请输入姓名' maxLength={10} />
                    </Form.Item>
                    <Form.Item name='contact_mobile' label={
                        <><img className="label" src={mobileImg}></img>手机号</>
                    } rules={[{ required: true }]}>
                        <Input placeholder='请输入手机号' maxLength={11} />
                    </Form.Item>
                    <Form.Item name='sex' label={
                        <><img className="label" src={sexImg}></img>性别</>
                    }>
                        <Radio.Group>
                            <Space direction='vertical'>
                                <Radio value={1}>男</Radio>
                                <Radio value={2}>女</Radio>
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        name='birthday'
                        label={
                            <><img className="label" src={birthdayImg}></img>生日</>
                        }
                        trigger='onConfirm'
                        onClick={(e, datePickerRef: RefObject<DatePickerRef>) => {
                            datePickerRef.current?.open()
                        }}
                    >
                        <DatePicker
                            min={dayjs('1920-01-01').toDate()}
                            max={dayjs().toDate()}
                        >
                            {value =>
                                value ? dayjs(value).format('YYYY-MM-DD') : '请选择日期'
                            }
                        </DatePicker>
                    </Form.Item>

                    <Form.Item name='company_name' label='企业名称' rules={[{ required: true }]}>
                        <Input placeholder='请输入企业名称' />
                    </Form.Item>

                    <Form.Item name='duty_id' label={
                        <><img className="label" src={positionImg}></img>职务</>
                    } rules={[{ required: true }]}>
                        <Selector
                            columns={3}
                            options={dutyList?.map(item => ({ label: item.name, value: item.value })) ?? []}
                        />
                    </Form.Item>
                    {/* 
            <Form.Item name='business_id' trigger="onConfirm" label='行业' rules={[{ required: true }]}>
                <Picker
                    columns={[businessList?.map(item => ({ label: item.name, value: String(item.value) })) ?? []]}
                >
                    {(items, actions) => (
                        <div className="picker-content">
                            <Button className="button" onClick={actions.open}>请选择</Button>
                            <div className="text">{items.map(item => item?.label ?? '').join('-')}</div>
                        </div>
                    )}
                </Picker>
            </Form.Item>

            <Form.Item name='scale_id' trigger="onConfirm" label='人员规模' rules={[{ required: true }]}>
                <Picker
                    columns={[scaleList?.map(item => ({ label: item.name, value: String(item.value) })) ?? []]}
                >
                    {(items, actions) => (
                        <div className="picker-content">
                            <Button className="button" onClick={actions.open}>请选择</Button>
                            <div className="text">{items.map(item => item?.label ?? '').join('-')}</div>
                        </div>
                    )}
                </Picker>
            </Form.Item>

            <Form.Item name="location" trigger="onConfirm" label='所在地区' rules={[{ required: true, validator: locationValidator }]}>
                <Cascader
                    options={options}
                    onSelect={value => {
                        value.forEach((v, index) => {
                            fetchOptionsForValue(v, index + 1)
                        })
                    }}

                >
                    {(items, actions) => (
                        <div className="picker-content">
                            <Button className="button" onClick={actions.open}>请选择</Button>
                            <div className="text">{items.map(item => item?.label ?? '').join('-')}</div>
                        </div>
                    )}
                </Cascader>
            </Form.Item>

            <Form.Item name='address' label='详细地址' rules={[{ required: true }]}>
                <Input placeholder='请输入详细地址' />
            </Form.Item>

            <Form.Item name='source_type' trigger="onConfirm" label='信息来源'>
                <Picker
                    columns={[sourceTypeList?.map(item => ({ label: item.name, value: item.name })) ?? []]}
                >
                    {(items, actions) => (
                        <div className="picker-content">
                            <Button className="button" onClick={actions.open}>请选择</Button>
                            <div className="text">{items.map(item => item?.label ?? '').join('-')}</div>
                        </div>
                    )}
                </Picker>
            </Form.Item> */}
                </Form>
            </div>
        </div>
    );
}

export default InfoPage;