import { Button, Card, Skeleton, Toast } from "antd-mobile";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getCaseDetail, ICaseDetailData } from "../../../apis/case";

import "./questionDetail.scss";
import { login } from "../../../apis/user";

// 提取地址中的参数值
function getUrlKeyVal(key: string) {
    let r = new RegExp(`[?|&]${key}=([^&;]+?)(&|#|;|$)`);
    let arr = r.exec(window.location.href) ?? [undefined, ''];
    let s = arr[1]!.replace(/\+/g, '%20');
    return decodeURIComponent(s) || undefined;
}

function QuestionDetailPage() {
    document.title = '问题详情';

    const [uRLSearchParams, setURLSearchParams] = useSearchParams();

    const [data, setData] = useState<ICaseDetailData>();

    const navigate = useNavigate();

    useEffect(() => {
        let token = sessionStorage.getItem('token');

        if (!token) {
            let code = getUrlKeyVal('code');
            if (code) {
                if (getUrlKeyVal('test')) {
                    Toast.clear();
                    return;
                }
                // 换取token
                let scope = getUrlKeyVal('state') ?? '';
                let agentId = getUrlKeyVal('agent_id');
                login({ code, scope, agent_id: agentId }).then(async (res) => {
                    // 存储token
                    sessionStorage.setItem('token', res.data.token);

                    getCaseDetail({ id: Number(uRLSearchParams.get('id')) }).then(res => {
                        setData(res.data.detail);
                    });
                })
            } else {
                // 静默授权
                getWeChatCode('snsapi_base');
            }
        } else {
            getCaseDetail({ id: Number(uRLSearchParams.get('id')) }).then(res => {
                setData(res.data.detail);
            })
        }
    }, []);

    // 请求通过微信官方接口 获取code
    function getWeChatCode(scope = 'snsapi_userinfo') {
        if (scope === 'snsapi_userinfo') sessionStorage.removeItem('token');
        // 获取当前页面地址作为回调地址
        let local = encodeURIComponent(window.location.href);
        console.log(local);
        let appid = 'wxf64fab5b9ecdfc50';// 自己填入测试号信息
        // 访问设置的回调地址 redirect_uri，会重定向显示带code参数的地址
        window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + appid +
            "&redirect_uri=" + local +
            `&response_type=code&scope=${scope}&state=${scope.split('_')[1]}#wechat_redirect`;
    }

    return (
        <div className="question-detail">
            <div className="question">
                <div className='question-header'>
                    {
                        data
                            ? <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img className='avatar' src={data.oauth_user.headimgurl} alt='avatar' />
                                {data.oauth_user.nickname}
                            </div>
                            : <Skeleton.Title />
                    }
                    <a href="/">返回首页</a>
                </div>
                {
                    data
                        ? <div className='question-content'>
                            <div>{data.content}</div>
                            <div className="date">{data.created_at}</div>
                        </div>
                        : <Skeleton.Paragraph />
                }
            </div>
            {
                data && data.is_reply && data.reply_adviser &&
                <div className="answer">
                    <Card
                        className='card'
                        extra={<span style={{ color: 'gray', fontSize: 14 }}>{data.reply_adviser.level_text}</span>}
                        title={
                            <div className='card-header'>
                                <img className='avatar' src={data.reply_adviser.avatar_link} alt='avatar' />
                                {data.reply_adviser.real_name}
                            </div>
                        }
                    >
                        <div className='card-content'>{data.reply}</div>
                    </Card>
                </div>
            }

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {/* <Button size='small' style={{ marginRight: 10 }} onClick={() => navigate('/adviser/')}>{'预约顾问 >>'}</Button> */}
                <Button size='small' style={{ marginRight: 10 }} onClick={() => navigate('/reservation')}>{'预约顾问 >>'}</Button>
            </div>

            <div style={{ color: '#6284FF', fontSize: 12, margin: 10 }}>注意：如果想更细致了解问题的解决方案可预约顾问进行下线沟通</div>
        </div>
    );
}

export default QuestionDetailPage;