import { Button, InfiniteScroll, List, SearchBar } from "antd-mobile"
import { useState } from "react"
import { getBillList, IBillData, IBillSearchForm } from "../../apis/bill"
import './bill.scss';

export function BillPage() {
    document.title = '交易记录';

    const [data, setData] = useState<IBillData[]>([])
    const [hasMore, setHasMore] = useState(true)
    const [keywords, setKeywords] = useState<string>()

    const [search, setSearch] = useState<IBillSearchForm>({
        page: 0,
        size: 20,
    })

    async function loadMore() {
        let newSearch = {
            ...search,
            page: search.page + 1
        };
        setSearch(newSearch);
        return loadData(newSearch);
    }

    const loadData = async (data: IBillSearchForm) => {
        let res = await getBillList(data);
        const append = res.data.list;
        setData(val => {
            let newData = [...val, ...append];
            setHasMore(newData.length < res.data.total);
            return newData;
        })
    }

    function doSearch() {
        setData([]);
        setSearch({ page: 0, size: 20, keywords: keywords });
        setHasMore(true);
        loadMore();
    }

    return (
        <div className="bill">
            <div className="header">
                <div className="left">
                    <SearchBar value={keywords} onChange={setKeywords} />
                </div>
                <div className="right">
                    <Button size='small' color='primary' onClick={doSearch}>
                        搜索
                    </Button>
                </div>
            </div>
            <List>
                {data.map((item, index) => (
                    <List.Item key={index} description={item.pay_time} extra={`${item.money}元`}>{item.title}</List.Item>
                ))}
            </List>
            <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
        </div>
    )
}