import { Button, Checkbox, Form, Picker, Space, TextArea, Toast } from "antd-mobile";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getDictList, getWxConfig, IDictDataOption } from "../../apis/common";

import bgImg from '../../assets/info/bg.svg';
import { ICaseForm, toPostQuestion } from "../../apis/case";
import wx from 'weixin-js-sdk';

// 提取地址中的参数值
function getUrlKeyVal(key: string) {
    let r = new RegExp(`[?|&]${key}=([^&;]+?)(&|#|;|$)`);
    let arr = r.exec(window.location.href) ?? [undefined, ''];
    let s = arr[1]!.replace(/\+/g, '%20');
    return decodeURIComponent(s) || undefined;
}

const onFinishFailed = () => {
    Toast.show({
        icon: 'fail',
        content: '填写有误，请检查',
        duration: 2000,
        maskClickable: false
    })
}

function QuestionPage() {
    document.title = '我要提问';

    const navigate = useNavigate();

    const [form] = Form.useForm<ICaseForm>();
    const onFinish = () => {
        Toast.show({
            icon: 'loading',
            content: '正在提交',
            duration: 0,
            maskClickable: false
        })
        const values = form.getFieldsValue(true);
        console.log(values);
        let newForm: ICaseForm = JSON.parse(JSON.stringify(values));
        newForm.type_id = Number(values.type_id[0]);

        toPostQuestion(newForm).then((res) => {
            Toast.clear();
            Toast.show({
                icon: 'success',
                content: res.msg,
                duration: 2000,
                afterClose: () => {
                    let appid = 'wxf64fab5b9ecdfc50';// 自己填入测试号信息
                    let local = encodeURIComponent(window.location.origin);
                    let template_id = 'uyYbv2LO01r1N5a0NYp3M4c9wNBaI7FCS95lztJ8Iqs';
                    window.location.replace(`https://mp.weixin.qq.com/mp/subscribemsg?action=get_confirm&appid=${appid}&scene=1000&template_id=${template_id}&redirect_url=${local}#wechat_redirect`);
                },
            });
        });
    }

    const [typeList, setTypeList] = useState<IDictDataOption[]>([]);

    // 初始化
    useEffect(() => {
        getWxConfig().then(({ data }) => {
            wx.config({
                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                appId: data.appId, // 必填，公众号的唯一标识
                timestamp: data.timestamp, // 必填，生成签名的时间戳
                nonceStr: data.nonceStr, // 必填，生成签名的随机串
                signature: data.signature,// 必填，签名
                jsApiList: ["chooseWXPay"], // 必填，需要使用的JS接口列表chooseWXPay为支付js接口
                openTagList: ['wx-open-subscribe'] // 服务号订阅通知按钮
            });
        });

        Toast.show({
            icon: 'loading',
            content: '加载中',
            duration: 0,
            maskClickable: false
        });

        getDictList(['vip_question_type']).then((res) => {
            setTypeList(res.data.vip_question_type!.options);
        }).finally(() => {
            Toast.clear();
        });

    }, []);

    return (
        <div className="info">
            <div className="bg">
                <img className="bg__img" src={bgImg} />
                <div className="bg__text">
                    <div className="bg__text__title">发起提问</div>
                    <div className="bg__text__sub">专家为您解答</div>
                </div>
            </div>
            <div className="form-box">
                <Form
                    className="form"
                    form={form}
                    name='form'
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    initialValues={{
                        is_anonymity: false,
                    }}
                    footer={
                        <>
                            <Button block type='submit' color='primary' size='large'>
                                提交
                            </Button>
                        </>
                    }
                >
                    <Form.Item trigger="onConfirm" name='type_id' label="问题类别" rules={[{ required: true }]}>
                        <Picker
                            columns={[typeList?.map(item => ({ label: item.name, value: String(item.value) })) ?? []]}
                        >
                            {(items, { open }) => {
                                return (
                                    <Space align='center'>
                                        <Button onClick={open}>选择</Button>
                                        {items.every(item => item === null)
                                            ? '未选择'
                                            : items.map(item => item?.label ?? '未选择').join(' - ')}
                                    </Space>
                                )
                            }}
                        </Picker>
                    </Form.Item>

                    <Form.Item name='content' label='问题内容' rules={[{ required: true }]}>
                        <TextArea placeholder='请填写您的问题' rows={5} />
                    </Form.Item>

                    <Form.Item name='is_anonymity' valuePropName="checked">
                        <Checkbox>匿名提问</Checkbox>
                    </Form.Item>

                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        {/* <Button size='small' style={{ marginRight: 10 }} onClick={() => navigate('/adviser/')}>{'预约顾问 >>'}</Button> */}
                        <Button size='small' style={{ marginRight: 10 }} onClick={() => navigate('/reservation')}>{'预约顾问 >>'}</Button>
                    </div>

                    <div style={{ color: '#6284FF', fontSize: 12, margin: 10 }}>注意：如果想更细致了解问题的解决方案可预约顾问进行下线沟通</div>
                </Form>
            </div>
        </div>
    );
}

export default QuestionPage;