import { axiosInstance, CommonResponse } from ".";

export interface ICaseListData {
    id: number,
    type_id: number,
    content: string,
    is_reply: boolean,
    created_at: string,
    type_text: string,
    oauth_user: {
        oauth_user_id: string,
        nickname: string,
        headimgurl: string
    }
}


interface ICaseList {
    list: ICaseListData[]
}

interface ICaseListSearchForm {
    page: number,
    size: number,
    keywords?: string,
    type_id?: number
}

/**
 * @description: 案例分析列表
 * @returns {Promise}
 */
export const getCaseList = (data: ICaseListSearchForm): Promise<CommonResponse<ICaseList>> => {
    return axiosInstance.post('/vip/question/list', data).then(res => res.data);
}

export interface ICaseForm {
    type_id: number,
    content: string,
    is_anonymity: boolean
}

/**
 * @description: 案例发问
 * @returns {Promise}
 */
export const toPostQuestion = (data: ICaseForm): Promise<CommonResponse> => {
    return axiosInstance.post('/vip/question/ask', data).then(res => res.data);
}

export interface ICaseDetailData {
    id: number,
    oauth_user_id: number,
    type_id: number,
    content: string,
    is_reply: boolean,
    reply?: string,
    created_at: string,
    type_text?: string,
    oauth_user: {
        id: number,
        nickname: string,
        headimgurl: string
    },
    reply_adviser?: {
        id: number,
        real_name: string,
        avatar_id: number,
        level_id: number,
        expertise: string,
        info: string,
        avatar_link: string,
        sex_text?: string,
        level_text: string
    }
}

interface ICaseDetail {
    detail: ICaseDetailData
}


/**
 * @description: 案例分析详情
 * @returns {Promise}
 */
export const getCaseDetail = (data: { id: number }): Promise<CommonResponse<ICaseDetail>> => {
    return axiosInstance.post('/vip/question/detail', data).then(res => res.data);
}

export interface IMyAskListData {
    id: number,
    type_id: number,
    content: string,
    created_at: string,
    type_text: string,
    is_reply: boolean;
}

interface IMyAskList {
    list: IMyAskListData[]
}

/**
 * @description: 我的发问列表
 * @returns {Promise}
 */
export const getMyAskList = (data: ICaseListSearchForm): Promise<CommonResponse<IMyAskList>> => {
    return axiosInstance.post('/vip/question/my_ask', data).then(res => res.data);
}


export interface IMyListenListData {
    id: number,
    question_id: number,
    created_at: string,
    question: {
        id: number,
        type_id: number,
        content: string,
        is_reply: boolean,
        reply?: string,
        type_text?: string
        oauth_user_id: number,
        oauth_user: {
            oauth_user_id: string,
            nickname: string,
            headimgurl: string
        }
        reply_adviser_id: number,
    },
}




interface IMyListenList {
    list: IMyListenListData[]
}

/**
 * @description: 我的旁听列表
 * @returns {Promise}
 */
export const getMyListenList = (data: ICaseListSearchForm): Promise<CommonResponse<IMyListenList>> => {
    return axiosInstance.post('/vip/question/my_listen', data).then(res => res.data);
}

export interface IMyBalance {
    balance: number
}

/**
 * @description: 我的发问余额
 * @returns {Promise}
 */
export const getMyAskBalance = (): Promise<CommonResponse<IMyBalance>> => {
    return axiosInstance.get('/vip/question/my_ask_balance').then(res => res.data);
}

/**
 * @description: 我的旁听余额
 * @returns {Promise}
 */
export const getMyListenBalance = (): Promise<CommonResponse<IMyBalance>> => {
    return axiosInstance.get('/vip/question/my_listen_balance').then(res => res.data);
}

/**
 * @description: 是否已旁听
 * @returns {Promise}
 */
export const getCaseListen = (data: { id: number }): Promise<CommonResponse<{ is_listen: boolean }>> => {
    return axiosInstance.post('/vip/question/check_listen', data).then(res => res.data);
}

/**
 * @description: 撤销提问
 * @returns {Promise}
 */
export const cancelCaseQuestion = (data: { id: number }): Promise<CommonResponse> => {
    return axiosInstance.post('/vip/question/cancel', data).then(res => res.data);
}

export interface IMyQuestionReplyListData {
    id: number,
    content: string,
    reply_at: string,
    type_text: string
}


export interface IMyQuestionReplyList {
    list: IMyQuestionReplyListData[],
    count: number
}

/**
 * @description: 我的发问回复弹窗提醒列表
 * @returns {Promise}
 */
export const getMyQuestionReplyList = (): Promise<CommonResponse<IMyQuestionReplyList>> => {
    return axiosInstance.get('/vip/question/my_ask_remind').then(res => res.data);
}