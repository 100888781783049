import { Skeleton, Image, Space, Button } from "antd-mobile";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getAdviserDetail, IAdviserDetailData } from "../../apis/adviser";

import "./adviserDetail.scss";

function AdviserDetailPage() {
    document.title = '顾问详情';

    const navigate = useNavigate();

    const [uRLSearchParams, setURLSearchParams] = useSearchParams();

    const [data, setData] = useState<IAdviserDetailData>();

    useEffect(() => {
        getAdviserDetail({ id: Number(uRLSearchParams.get('id')) }).then(res => {
            setData(res.data.detail);
        })
    }, []);

    return (
        <div className="adviser-detail">
            {
                data
                    ? <div className="header">
                        <Image
                            src={data.avatar_link}
                            style={{ borderRadius: 20 }}
                            fit='cover'
                            width={60}
                            height={60}
                        />
                        <div style={{ marginLeft: 10 }}>
                            <div style={{ fontSize: 16, fontWeight: 'bold' }}>{data.real_name}</div>
                            <div style={{ marginTop: 10 }}>{data.level_text}</div>
                        </div>
                    </div>
                    : <div className="skeleton">
                        <Skeleton.Title animated />
                        <Skeleton.Paragraph lineCount={2} animated />
                    </div>
            }

            {
                data &&
                <Space className="sub-header" direction="vertical">
                    <div>专长：{data.expertise}</div>
                    <div>区域：{data.region.map(e => e.area_text).join('，')}</div>
                </Space>
            }


            {
                data
                    ? <div className="info">{data.info}</div>
                    : <div className="skeleton">
                        <Skeleton.Paragraph lineCount={5} animated />
                    </div>
            }


            <div style={{ height: 40 }}></div>

            <div className="button">
                <Button disabled={!data} block color='primary' size='large' onClick={
                    () => navigate(`/reservation?id=${data!.id}`)
                }>
                    预约
                </Button>
            </div>
        </div>
    );
}

export default AdviserDetailPage;