import { axiosInstance, CommonResponse } from ".";

/**
 * 字典
 */
export type IDictName = keyof IDictList

export interface IDictDataOption {
    name: string,
    value: number
}

export interface IDictData {
    type_name: string,
    options: IDictDataOption[]
}

export interface IDictList {
    duty?: IDictData, // 职务
    scale?: IDictData, // 企业规模
    business?: IDictData, // 行业
    source_type?: IDictData, // 来源
    vip_question_type?: IDictData, // 案例分析分类
}

/**
 * @description: 获取服务器字典列表
 * @returns {Promise}
 */
export const getDictList = (types: IDictName[]): Promise<CommonResponse<IDictList>> => {
    return axiosInstance.get('/api/shares/options', { params: { types } }).then(res => res.data);
};

export interface IWxConfig {
    appId: string,
    nonceStr: string,
    timestamp: number,
    signature: string
}

/**
 * @description: 获取服务器字典列表
 * @returns {Promise}
 */
export const getWxConfig = (): Promise<CommonResponse<IWxConfig>> => {
    return axiosInstance.get('/api/wechat/jssdk_config').then(res => res.data);
}

export interface RegionListData {
    id: number,
    name: string,
    initial: string
}

interface RegionList {
    items: RegionListData[]
}

/**
 * @description: 获取省市区字典列表
 * @returns {Promise}
 */
export const getRegionByPid = (params: { pid?: number }): Promise<CommonResponse<RegionList>> => {
    return axiosInstance.get('/api/shares/region', { params }).then(res => res.data);
}

