import { Button, Image, CascaderOption, CenterPopup, DatePicker, DatePickerRef, Dialog, Form, Input, Picker, Radio, Selector, Space, Switch, TextArea, Toast } from "antd-mobile";
import { RefObject, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getDictList, getRegionByPid, getWxConfig, IDictDataOption } from "../../apis/common";

import bgImg from '../../assets/info/bg.svg';
import { ICaseForm, toPostQuestion } from "../../apis/case";
import dayjs from "dayjs";
import { getReservationDetail, IAdviserForm, IReservationDetail, toReservation } from "../../apis/adviser";

import wx from 'weixin-js-sdk';
import { IVipPay, IVipPayType1, IVipPayType2 } from "../../apis/vip";

const onFinishFailed = () => {
    Toast.show({
        icon: 'fail',
        content: '填写有误，请检查',
        duration: 2000,
        maskClickable: false
    })
}

function ReservationPage() {
    document.title = '预约顾问';

    const [uRLSearchParams, setURLSearchParams] = useSearchParams();

    const navigate = useNavigate();

    const [detail, setDetail] = useState<IReservationDetail>();
    const loadData = async () => {
        let res = await getReservationDetail();
        setDetail(res.data);
    }

    useEffect(() => {
        loadData();

        getWxConfig().then(({ data }) => {
            wx.config({
                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                appId: data.appId, // 必填，公众号的唯一标识
                timestamp: data.timestamp, // 必填，生成签名的时间戳
                nonceStr: data.nonceStr, // 必填，生成签名的随机串
                signature: data.signature,// 必填，签名
                jsApiList: ["chooseWXPay"] // 必填，需要使用的JS接口列表chooseWXPay为支付js接口
            });
        });
    }, [])

    const [form] = Form.useForm<IAdviserForm>();
    const onFinish = () => {
        Toast.show({
            icon: 'loading',
            content: '正在提交',
            duration: 0,
            maskClickable: false
        })
        const values = form.getFieldsValue(true);
        let newForm: IAdviserForm = JSON.parse(JSON.stringify(values));
        newForm.adviser_id = uRLSearchParams.get('id') ? Number(uRLSearchParams.get('id')) : undefined;
        newForm.booking_at = dayjs(values.booking_at).format('YYYY-MM-DD HH:mm');
        // newForm.time_period = Number(newForm.time_period);

        console.log(newForm);

        toReservation(newForm).then((res) => {
            Toast.clear();
            payFunc(res.data);
        });
    }

    // 支付二维码弹出
    const [payQRcodeVisible, setPayQRcodeVisible] = useState(false);
    const [payQRcode, setPayQRcode] = useState<string>();
    const paid = async () => {
        const result = await Dialog.confirm({
            content: '确定已经支付？即将跳转回首页。',
            cancelText: '还未支付'
        })
        if (result) {
            setPayQRcodeVisible(false);
            navigate('/', { replace: true });
        }
    }

    // 支付执行方法
    const payFunc = (data: IVipPay) => {
        switch (data.pay_type) {
            case 1:
                /**
                 * 微信支付
                 */
                let payment1 = data.payment as IVipPayType1;
                wx.chooseWXPay({
                    timestamp: payment1.timestamp, // 支付签名时间戳，注意微信 jssdk 中的所有使用 timestamp 字段均为小写。但最新版的支付后台生成签名使用的 timeStamp 字段名需大写其中的 S 字符
                    nonceStr: payment1.nonceStr, // 支付签名随机串，不长于 32 位
                    package: payment1.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
                    signType: payment1.signType, // 微信支付V3的传入 RSA ,微信支付V2的传入格式与V2统一下单的签名格式保持一致
                    paySign: payment1.paySign, // 支付签名
                    success: (res) => {
                        console.log(res);
                        if (res.errMsg === 'chooseWXPay:ok') {
                            // 支付成功后的回调函数
                            Toast.show({
                                icon: 'success',
                                content: '支付成功，你的预约已提交！',
                                duration: 5000,
                                afterClose: () => navigate('/', { replace: true }),
                                maskClickable: false
                            });
                        } else {
                            Toast.show({
                                icon: 'fail',
                                content: `支付未成功: ${res.errMsg}`,
                                duration: 5000,
                                maskClickable: false
                            })
                        }
                    }
                });
                break;
            case 2:
                /**
                 * 二维码支付
                 */
                let payment2 = data.payment as IVipPayType2;
                setPayQRcode(payment2.offline_pay_url);
                setPayQRcodeVisible(true);
                break;

            default:
                Toast.show({
                    icon: 'fail',
                    content: '支付响应参数错误',
                    duration: 5000,
                    maskClickable: false
                })
                break;
        }
    }

    return (
        <div className="info">
            <div className="bg">
                <img className="bg__img" src={bgImg} />
                <div className="bg__text">
                    <div className="bg__text__title">{detail?.title ?? '预约顾问'}</div>
                    {detail?.money && <div className="bg__text__price">预约费用：{detail?.money}元/次</div>}
                    <div className="bg__text__sub">{detail?.service_intro ?? '专家线下解答'}</div>
                </div>
            </div>
            <div className="form-box">
                <Form
                    className="form"
                    form={form}
                    name='form'
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    initialValues={{
                        "can_adjust": true
                    }}
                    footer={
                        <Button block type='submit' color='primary' size='large'>
                            提交
                        </Button>
                    }
                >
                    <Form.Item name='real_name' label='姓名' rules={[{ required: true }]}>
                        <Input placeholder='请填写您的姓名' maxLength={10} />
                    </Form.Item>

                    <Form.Item name='mobile' label='手机号' rules={[{ required: true }]}>
                        <Input placeholder='请填写您的手机号' type="tel" maxLength={11} />
                    </Form.Item>

                    <Form.Item
                        name='booking_at'
                        label='预约时间'
                        trigger='onConfirm'
                        rules={[{ required: true }]}
                        onClick={(e, datePickerRef: RefObject<DatePickerRef>) => {
                            datePickerRef.current?.open()
                        }}
                    >
                        <DatePicker
                            min={dayjs().add(6, 'hours').toDate()}
                            max={dayjs().add(1, 'years').toDate()}
                            precision='minute'
                        >
                            {value =>
                                value ? dayjs(value).format('YYYY-MM-DD HH:mm') : '请选择预约时间'
                            }
                        </DatePicker>
                    </Form.Item>

                    {/* <Form.Item
                        name='time_period'
                        label='时间段'
                        extra={
                            <div style={{ marginTop: 26 }}>小时</div>
                        }
                        rules={[{ required: true }]}
                    >
                        <Input placeholder='请填写时间段' type="number" min={1} max={24} />
                    </Form.Item> */}

                    <Form.Item valuePropName="checked" name='can_adjust' label='是否接受时间调整' rules={[{ required: true }]}>
                        <Switch uncheckedText='否' checkedText='是' />
                    </Form.Item>

                    <Form.Item name='content' label='约见内容' rules={[{ required: true }]}>
                        <TextArea placeholder='请填写您的约见内容' rows={3} />
                    </Form.Item>
                </Form>
            </div>

            {/* 支付二维码弹出 */}
            <CenterPopup
                visible={payQRcodeVisible}
                closeOnMaskClick={false}
                showCloseButton={true}
                onClose={() => {
                    setPayQRcodeVisible(false)
                }}
                bodyStyle={{ height: '80vh' }}
            >
                <div className='qrcode-box'>
                    <Image className='qrcode-box__img' src={payQRcode} />
                    <Button className='qrcode-box__btn' color='primary' onClick={paid}>我已支付</Button>
                </div>

            </CenterPopup>
        </div>
    );
}

export default ReservationPage;