import { Toast } from 'antd-mobile';
import axios, { AxiosInstance, AxiosResponse } from 'axios';

// 返回res.data的interface
export interface CommonResponse<T = any> {
    "code": number,
    "msg": string,
    "data": T
}

export let axiosInstance: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    withCredentials: false,
});

// axios实例拦截响应
axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => {
        // 更新token
        if (response.headers.Token) {
            sessionStorage.setItem('app_token', response.headers.Token);
        }


        // 请求成功
        if (response.status === 200) {
            if (response.data.code === 200) {
                return response;
            }

            // token失效
            if (response.data.code === 401) {
                sessionStorage.removeItem('token');
                window.location.href = '/';
            }

            // 错误提示
            Toast.clear();
            Toast.show({
                content: response.data.msg,
                duration: 3000,
                position: 'bottom',
            });

            return Promise.reject(response);
        }

        // 状态码错误
        Toast.clear();
        Toast.show({
            content: response.status,
            duration: 3000,
            position: 'bottom',
        });
        return Promise.reject(response);
    },
    // 请求失败
    (error: any) => {
        const { response } = error;
        if (response) {
            // 请求已发出，但是不在2xx的范围
            Toast.clear();
            Toast.show({
                content: response.status,
                duration: 3000,
                position: 'bottom',
            })
            return Promise.reject(response);
        } else {
            Toast.clear();
            Toast.show({
                content: '网络连接异常，请稍后再试！',
                duration: 3000,
                position: 'bottom',
            })
        }
    }
);

// axios实例拦截请求
axiosInstance.interceptors.request.use(
    (config) => {
        const token = sessionStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`
        }
        return config;
    },
    (error: any) => {
        return Promise.reject(error);
    }
)

