import { axiosInstance, CommonResponse } from ".";

export interface IBillData {
    id: number,
    ordersn: string,
    title: string,
    money: number,
    pay_time: string
}

interface IBillListRes {
    list: IBillData[],
    total: number
}

export interface IBillSearchForm {
    page: number,
    size?: number,
    keywords?: string
}

/**
 * @description: 获取账单列表
 * @returns {Promise}
 */
export const getBillList = (data: IBillSearchForm): Promise<CommonResponse<IBillListRes>> => {
    return axiosInstance.post('/vip/order/list', data).then(res => res.data);
};
