import { axiosInstance, CommonResponse } from ".";

export interface ICaseProjectData {
    id: number,
    title: string,
    price: number,
    listen_times: number,
    ask_times: number,
    duration_type: number,
    duration: number,
    duration_type_text: string
}


interface ICaseProjectRes {
    list: ICaseProjectData[]
}

/**
 * @description: 获取套餐列表
 * @returns {Promise}
 */
export const getCaseProjectList = (data: { package_type: number }): Promise<CommonResponse<ICaseProjectRes>> => {
    return axiosInstance.get('/vip/config/question_package', {
        params: data
    }).then(res => res.data);
}

export interface ICasePayType1 {
    nonceStr: string,
    package: string,
    signType: string,
    paySign: string,
    timestamp: number,
}

export interface ICasePayType2 {
    offline_pay_url: string
}
export interface ICasePay {
    payment: ICasePayType1 | ICasePayType2,
    pay_type: 1 | 2
}

interface ICasePayForm {
    package_id: number
}

/**
 * @description: 购买
 * @returns {Promise}
 */
export const toCasePay = (data: ICasePayForm): Promise<CommonResponse<ICasePay>> => {
    return axiosInstance.post('/vip/question_order/pay', data).then(res => res.data);
};

interface IOrderCheckRes {
    money: number
}

/**
 * @description: 获取购买项目数据
 * @returns {Promise}
 */
export const checkCaseOrder = (data: ICasePayForm): Promise<CommonResponse<IOrderCheckRes>> => {
    return axiosInstance.post('/vip/question_order/check', data).then(res => res.data);
}

