import './mine.scss';
import logo from '../../assets/logo.svg';
import avatar from '../../assets/avatar.svg';
import wave from '../../assets/wave.svg';
import wave2 from '../../assets/wave2.svg';
import vipIcon2 from '../../assets/vip_icon2.svg';
import help from '../../assets/help.svg';
import bg from '../../assets/bg.svg';
import arrowRight from '../../assets/arrow_right.svg';
import contractIcon from '../../assets/icons/contract.svg';
import purchaseIcon from '../../assets/icons/purchase.svg';
import payIcon from '../../assets/icons/pay.svg';
import reimbursementIcon from '../../assets/icons/reimbursement.svg';
import correctCardIcon from '../../assets/icons/correct_card.svg';

import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUserInfo, IUserInfoData, login } from '../../apis/user';
import { ActionSheet, Dialog, Divider, PullToRefresh, TabBar, Toast } from 'antd-mobile';
import { getCustomInfo, ICustomerInfoData } from '../../apis/custom';

import {
    AppOutline,
    UserOutline,
} from 'antd-mobile-icons';
import type {
    Action,
    ActionSheetShowHandler,
} from 'antd-mobile/es/components/action-sheet'

function MinePage() {
    document.title = '会员中心';

    const navigate = useNavigate();

    // 用户信息
    const [userInfo, setUserInfo] = useState<IUserInfoData>();

    // 客户信息
    const [customerInfo, setCustomerInfo] = useState<ICustomerInfoData>();

    // 获取用户信息
    const initUserInfo = async () => {
        console.log('========获取用户信息');

        let userInfoRes = await getUserInfo();
        if (userInfoRes) {
            let userInfo = userInfoRes.data.info;
            // 是否有用户信息
            if (userInfo.is_auth) {
                sessionStorage.setItem('user', JSON.stringify(userInfo));
                setUserInfo(userInfo);
                if (userInfo.is_customer) {
                    setCustomerInfo((await getCustomInfo()).data.detail);
                } else {
                    // Dialog.confirm({
                    //     content: '前往完善会员信息',
                    //     onConfirm: () => {
                    //         navigate('/info/');
                    //     },
                    // });
                    navigate('/info/');
                }
            } else {
                getInfoDialog();
            }
        }

        Toast.clear();
    }

    // 初始化
    useEffect(() => {
        Toast.show({
            icon: 'loading',
            content: '加载中',
            duration: 0,
            maskClickable: false
        });

        let token = sessionStorage.getItem('token');
        if (!token) {
            let code = getUrlKeyVal('code');
            if (code) {
                if (getUrlKeyVal('test')) {
                    Toast.clear();
                    return;
                }
                // 换取token
                let scope = getUrlKeyVal('state') ?? '';
                let agentId = getUrlKeyVal('agent_id');
                login({ code, scope, agent_id: agentId }).then(async (res) => {
                    // 存储token
                    sessionStorage.setItem('token', res.data.token);

                    initUserInfo();
                })
            } else {
                // 静默授权
                getWeChatCode('snsapi_base');
            }
        } else {
            initUserInfo();
        }
    }, []);

    // 请求通过微信官方接口 获取code
    function getWeChatCode(scope = 'snsapi_userinfo') {
        if (scope === 'snsapi_userinfo') sessionStorage.removeItem('token');
        // 获取当前页面地址作为回调地址
        let local = encodeURIComponent(getUrlKeyVal('code') ? window.location.origin : window.location.href);
        console.log(local);
        let appid = 'wxf64fab5b9ecdfc50';// 自己填入测试号信息
        // 访问设置的回调地址 redirect_uri，会重定向显示带code参数的地址
        window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + appid +
            "&redirect_uri=" + local +
            `&response_type=code&scope=${scope}&state=${scope.split('_')[1]}#wechat_redirect`;
    }

    // 提取地址中的参数值
    function getUrlKeyVal(key: string) {
        let r = new RegExp(`[?|&]${key}=([^&;]+?)(&|#|;|$)`);
        let arr = r.exec(window.location.href) ?? [undefined, ''];
        let s = arr[1]!.replace(/\+/g, '%20');
        return decodeURIComponent(s) || undefined;
    }

    // 获取snsapi_userinfo授权
    const getInfoCode = () => {
        getWeChatCode();
    }

    // 下拉刷新
    // const onRefresh = async () => {
    //     let token = sessionStorage.getItem('token');
    //     if (token) {
    //         await initUserInfo();
    //     }
    // }

    const getInfoDialog = () => {
        Dialog.show({
            content: '授权您的基础信息，将更好的为您提供服务',
            closeOnAction: true,
            actions: [
                [
                    {
                        key: 'cancel',
                        text: '取消',
                    },
                    {
                        key: 'ok',
                        text: '授权',
                        bold: true,
                        danger: true
                    },
                ],
            ],
            onAction: (action, index) => {
                if (action.key === 'ok') {
                    getWeChatCode();
                }
            }
        });
    }

    // 跳转客户信息
    const toInfo = () => {
        if (userInfo?.is_auth) {
            navigate('/info/');
        } else {
            getInfoDialog();
        }
    }

    const loginCheck = (): boolean => {
        if (!userInfo?.is_auth) {
            getInfoDialog();
            return false;
        }

        if (!userInfo.is_customer) {
            Dialog.show({
                content: '请先完善您的信息',
                closeOnAction: true,
                actions: [
                    [
                        {
                            key: 'cancel',
                            text: '取消',
                        },
                        {
                            key: 'ok',
                            text: '确定',
                            bold: true,
                            danger: true
                        },
                    ],
                ],
                onAction: (action, index) => {
                    if (action.key === 'ok') {
                        navigate('/info/');
                    }
                }
            });
            return false;
        }

        return true;
    }

    // 跳转vip页
    const toVip = (isNav = true) => {
        if (!loginCheck()) {
            return;
        }

        if (isNav) {
            navigate('/vip/?nav=1');
        } else {
            navigate('/vip/');
        }
    }

    // 跳转项目购买
    const toProject = () => {
        if (!loginCheck()) {
            return;
        }

        navigate('/pay/project');
    }

    // 案例分析
    const handler = useRef<ActionSheetShowHandler>();
    const actions: Action[] = [
        {
            text: '我的发问',
            key: 'my_ask',
            onClick: () => {
                handler.current?.close();

                if (!loginCheck()) {
                    return;
                }

                navigate('/case/my_ask');
            },
        },
        {
            text: '我的旁听',
            key: 'my_listen',
            onClick: () => {
                handler.current?.close();

                if (!loginCheck()) {
                    return;
                }

                navigate('/case/my_listen');
            },
        },
        {
            text: '我的预约',
            key: 'my_booking',
            onClick: () => {
                handler.current?.close();

                if (!loginCheck()) {
                    return;
                }

                navigate('/case/my_booking');
            },
        }
    ]
    const toCase = () => {
        handler.current = ActionSheet.show({
            actions,
            onClose: () => {
                // Toast.show('动作面板关闭')
            },
        })
    }

    // 账单
    const toBill = () => {
        if (!loginCheck()) {
            return;
        }

        navigate('/bill/');
    }

    // 标签栏
    const tabs = [
        {
            key: 'home',
            title: '首页',
            icon: <AppOutline />,
        },
        {
            key: 'mine',
            title: '我的',
            icon: <UserOutline />,
        },
    ]
    const onTabBarChange = (key: string) => {
        switch (key) {
            case 'home':
                navigate('/', { replace: true });
                break;

            default:
                break;
        }
    }

    return (
        <div className='mine'>
            <div>
                <div className='top-box'>
                    <img className='wave' src={wave} alt='' />
                    <img className='wave' src={wave2} alt='' />
                    <div className='top-box__inner'>
                        <img className='avatar' src={userInfo ? userInfo.headimgurl : avatar} alt='avatar' />
                        {
                            userInfo?.is_auth
                                ? (
                                    <div style={{ fontSize: 18 }}>{userInfo.nickname}</div>
                                )
                                : (
                                    <div onClick={getInfoCode}>
                                        <div>未登录</div>
                                        <div style={{ fontSize: 12 }}>点击此处授权用户信息</div>
                                    </div>
                                )
                        }
                    </div>
                </div>
                <div className='bottom-box' style={{ backgroundColor: '#083D95' }}>
                    <img className='logo' src={logo} alt='logo' />
                    <div className='vip-box'>
                        <img className='bg' src={bg} alt='' />
                        <div className='info'>
                            <div className='state'>
                                <div className='state-box'>
                                    <img className='vip-icon' src={vipIcon2} alt="" />
                                    VIP特权
                                    <img className='help-icon' src={help} alt="" onClick={() => toVip(false)} />
                                </div>
                                <div className='customer'>{userInfo ? userInfo.is_vip ? `${userInfo.vip_expired_date}过期` : '暂未开通' : '暂未登录'}</div>
                            </div>

                            {
                                customerInfo
                                    ? (
                                        <div className='customer' onClick={toInfo}>
                                            <div>手机号：{customerInfo.contact_mobile}</div>
                                            <div>生日：{customerInfo.birthday ?? '-'}</div>
                                            <div>职务：{customerInfo.duty_text ?? '-'}</div>
                                        </div>
                                    )
                                    : userInfo ? <div className='customer' onClick={toInfo}>未完善用户信息，点击前往</div> : <></>
                            }

                        </div>
                        <div></div>
                    </div>
                </div>
                <div className='bottom-box'>
                    <div className='function-box'>
                        <div className='func-item' onClick={toInfo}>
                            <div className='func-item__left'>
                                <img className='func-item__icon' src={contractIcon} alt='' />
                                <div>完善信息</div>
                            </div>
                            <img className='func-item__arrow' src={arrowRight} alt='' />
                        </div>
                        <div className='func-item' onClick={() => toVip()}>
                            <div className='func-item__left'>
                                <img className='func-item__icon' src={reimbursementIcon} alt='' />
                                <div>会员开通/续费</div>
                            </div>
                            <img className='func-item__arrow' src={arrowRight} alt='' />
                        </div>
                        <div className='func-item' onClick={toProject}>
                            <div className='func-item__left'>
                                <img className='func-item__icon' src={purchaseIcon} alt='' />
                                <div>购买项目</div>
                            </div>
                            <img className='func-item__arrow' src={arrowRight} alt='' />
                        </div>
                        <div className='func-item' onClick={toCase}>
                            <div className='func-item__left'>
                                <img className='func-item__icon' src={correctCardIcon} alt='' />
                                <div>案例分析</div>
                            </div>
                            <img className='func-item__arrow' src={arrowRight} alt='' />
                        </div>
                        <div className='func-item' onClick={toBill}>
                            <div className='func-item__left'>
                                <img className='func-item__icon' src={payIcon} alt='' />
                                <div>交易记录</div>
                            </div>
                            <img className='func-item__arrow' src={arrowRight} alt='' />
                        </div>
                    </div>
                </div>
            </div>
            <div className='bottom'>
                <TabBar className='tabBar' safeArea activeKey="mine" onChange={onTabBarChange}>
                    {tabs.map(item => (
                        <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
                    ))}
                </TabBar>
            </div>
        </div>
    );
}

export default MinePage;