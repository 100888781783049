import { axiosInstance, CommonResponse } from ".";

interface ICustomerInfoRes {
    detail?: ICustomerInfoData
}

export interface ICustomerInfoData {
    id: number,
    company_name?: string,
    contact_mobile?: string,
    contact_name?: string,
    duty_id?: number,
    duty_text?: string,
    business_id?: number,
    scale_id?: number,
    birthday?: string,
    sex?: number,
    status_text: string,
    logo_img?: string,
    province?: string,
    province_id?: number,
    city?: string,
    city_id?: number,
    area?: string,
    area_id?: number,
    street?: string,
    street_id?: number,
    address?: string,
    source_type?: string
}

/**
 * @description: 获取客户信息
 * @returns {Promise}
 */
export const getCustomInfo = (): Promise<CommonResponse<ICustomerInfoRes>> => {
    return axiosInstance.get('/vip/customer/detail').then(res => res.data);
};


export interface ICustomerInfoForm {
    contact_name: string,
    contact_mobile: string,
    sex?: number,
    birthday?: string,
    company_name: string,
    duty_id: number,
    province_id?: number,
    city_id?: number,
    area_id?: number,
    street_id?: number,
    address?: string,
    business_id?: number,
    scale_id?: number
    source_type?: string
}

/**
 * @description: 更新客户信息
 * @param params 
 * @returns {Promise}
 */
export const updateCustomInfo = (data: ICustomerInfoForm): Promise<CommonResponse> => {
    return axiosInstance.post('/vip/customer/update', data).then(res => res.data);
};