import ReactDOM from 'react-dom/client';
import './index.css';
import MinePage from './views/mine/mine';
import reportWebVitals from './reportWebVitals';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import VipPage from './views/vip/vip';
import InfoPage from './views/info/info';
import { PayPage } from './views/pay/pay';
import { BillPage } from './views/bill/bill';
import CasePage from './views/case/case';
import QuestionPage from './views/case/question';
import QuestionDetailPage from './views/case/actions/questionDetail';
import AdviserPage from './views/adviser/adviser';
import AdviserDetailPage from './views/adviser/adviserDetail';
import ReservationPage from './views/adviser/reservation';
import MyAskPage from './views/case/myAsk';
import MyListenPage from './views/case/myListen';
import MyBookingPage from './views/case/myBooking';
import { CasePayPage } from './views/case/pay/pay';

const router = createBrowserRouter([
    {
        path: '/',
        element: <CasePage />,
    },
    {
        path: '/question/',
        element: <QuestionPage />
    },
    {
        path: '/questionDetail/',
        element: <QuestionDetailPage />
    },
    {
        path: "/mine",
        element: <MinePage />,
    },
    {
        path: '/vip/',
        element: <VipPage />
    },
    {
        path: '/info/',
        element: <InfoPage />
    },
    {
        path: '/pay/:type',
        element: <PayPage />
    },
    {
        path: '/bill/',
        element: <BillPage />
    },
    {
        path: '/adviser/',
        element: <AdviserPage />
    },
    {
        path: '/adviser/detail',
        element: <AdviserDetailPage />
    },
    {
        path: '/reservation',
        element: <ReservationPage />
    },
    {
        path: '/case/my_ask',
        element: <MyAskPage />
    },
    {
        path: '/case/my_listen',
        element: <MyListenPage />
    },
    {
        path: '/case/my_booking',
        element: <MyBookingPage />
    },
    {
        path: '/case/pay',
        element: <CasePayPage />
    }
]);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <RouterProvider router={router} />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
