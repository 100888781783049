import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ActionSheet, Card, Dialog, DotLoading, FloatingBubble, InfiniteScroll, List, NoticeBar, PullToRefresh, SearchBar, Space, Tabs, Toast } from 'antd-mobile';

import {
    ShopbagOutline
} from 'antd-mobile-icons';

import './case.scss';
import { cancelCaseQuestion, getMyAskBalance, getMyAskList, IMyAskListData } from '../../apis/case';
import { useDebounce } from 'ahooks';
import { getDictList, IDictDataOption } from '../../apis/common';
import { Action } from 'antd-mobile/es/components/action-sheet';

function MyAskPage() {
    document.title = '我的发问';

    const navigate = useNavigate();

    const [isInitLogin, setIsInitLogin] = useState(false);

    const toQuestionDetail = (id: number) => {
        setMActions({ visible: false, actions: [] });
        navigate(`/questionDetail?id=${id}`);
    }

    const toPay = () => {
        navigate(`/case/pay?type=2`);
    }

    const InfiniteScrollContent = ({ hasMore }: { hasMore?: boolean }) => {
        return (
            <>
                {hasMore ? (
                    <>
                        <span>加载中</span>
                        <DotLoading />
                    </>
                ) : (
                    <span>--- 没有更多数据 ---</span>
                )}
            </>
        )
    }

    const [data, setData] = useState<IMyAskListData[]>([]);
    const [hasMore, setHasMore] = useState(true);
    async function loadMore(isReload: boolean = false) {
        let _page = page
        if (isReload) {
            _page = 1;
        }

        let res = await getMyAskList({ page: _page, size, type_id: type, keywords });

        if (isReload) {
            setData(res.data.list);
        } else {
            setData(val => [...val, ...res.data.list]);
        }

        setHasMore(res.data.list.length === size);
        setPage(_page + 1);

        setIsInitLogin(true);
    }

    const onRefresh = async () => {
        console.log('refresh');
        loadMore(true);
    }

    // 搜索参数
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(20);
    const [type, setType] = useState<number>();
    const [keywords, setKeywords] = useState("");

    // 关键词搜索
    const keywordsDebouncedValue = useDebounce(keywords, { wait: 500 });
    useEffect(() => {
        if (isInitLogin) loadMore(true);
    }, [keywordsDebouncedValue]);

    // 类型筛选
    const [typeList, setTypeList] = useState<IDictDataOption[]>([]);
    useEffect(() => {
        getDictList(['vip_question_type']).then((res) => {
            setTypeList(res.data.vip_question_type!.options);
        });
    }, []);
    const onTabsChange = (key: string) => {
        console.log(key);
        if (key === '0') {
            setType(undefined);
        } else {
            setType(Number(key));
        }
    }
    useEffect(() => {
        if (isInitLogin) loadMore(true);
    }, [type]);

    // 余额
    const [balance, setBalance] = useState<number>();
    useEffect(() => {
        getMyAskBalance().then(res => {
            setBalance(res.data.balance);
        })
    }, [])

    const [mActions, setMActions] = useState<{
        visible: boolean,
        actions: Action[]
    }>({
        visible: false,
        actions: []
    });

    const toCancel = (id: number) => {
        setMActions({ visible: false, actions: [] });
        Dialog.confirm({
            content: '撤销后不可恢复，确定要撤销吗？',
            onConfirm: () => {
                cancelCaseQuestion({ id }).then(res => {
                    Toast.show({
                        icon: 'success',
                        content: res.msg,
                    });
                    onRefresh();
                })
            },
        });
    }

    return (
        <div className='case'>
            <div className='list'>
                <div className='search'>
                    <Space className='float' direction='vertical'>
                        <SearchBar placeholder='关键词搜索' onChange={setKeywords} />
                        <Tabs defaultActiveKey='0' onChange={onTabsChange}>
                            <Tabs.Tab title='全部' key='0' />
                            {
                                typeList.map(item => <Tabs.Tab title={item.name} key={item.value} />)
                            }
                        </Tabs>
                    </Space>
                </div>
                {
                    balance !== undefined &&
                    <NoticeBar content={`剩余提问次数：${balance}次`} closeable color='info' />
                }
                <ActionSheet
                    visible={mActions.visible}
                    actions={mActions.actions}
                    onClose={() => setMActions({
                        visible: false,
                        actions: []
                    })}
                />
                <List className='content'>
                    <PullToRefresh
                        onRefresh={onRefresh}
                    >
                        {data.map((item, index) => (
                            <Card
                                key={`list${item.id}`}
                                className='card'
                                extra={<span style={{ color: 'gray', fontSize: 14 }}>{item.created_at}</span>}
                                onClick={() => {
                                    setMActions({
                                        visible: true,
                                        actions: [
                                            {
                                                key: 'cancel',
                                                text: '撤销',
                                                disabled: item.is_reply,
                                                onClick: () => toCancel(item.id),
                                            },
                                            {
                                                key: 'detail',
                                                text: "查看详情",
                                                onClick: () => toQuestionDetail(item.id),
                                            },
                                        ],
                                    })
                                }}
                            >
                                <div className='card-content'>{item.content}</div>
                                <div className='card-footer' style={{ color: item.is_reply ? 'green' : '' }}>{item.is_reply ? '已回复' : '待回复'}</div>
                            </Card>
                        ))}
                    </PullToRefresh >
                </List>

                <InfiniteScroll loadMore={loadMore} hasMore={hasMore}>
                    <InfiniteScrollContent hasMore={hasMore} />
                </InfiniteScroll>

                <FloatingBubble
                    axis='xy'
                    magnetic='x'
                    style={{
                        '--initial-position-bottom': '60px',
                        '--initial-position-right': '15px',
                        '--edge-distance': '90px 15px 60px 15px',
                        '--size': '55px'
                    }}
                    onClick={toPay}
                >
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <ShopbagOutline fontSize={26} />
                        <div style={{ fontSize: 10 }}>购买次数</div>
                    </div>
                </FloatingBubble>
            </div>
        </div>
    );
}

export default MyAskPage;