import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, DotLoading, InfiniteScroll, List, PullToRefresh, SearchBar, Space, Tabs } from 'antd-mobile';

import './case.scss';
import { getMyBookingList, IMyBookingListData } from '../../apis/adviser';

function MyBookingPage() {
    document.title = '我的预约';

    const navigate = useNavigate();

    const [isInitLogin, setIsInitLogin] = useState(false);

    const InfiniteScrollContent = ({ hasMore }: { hasMore?: boolean }) => {
        return (
            <>
                {hasMore ? (
                    <>
                        <span>加载中</span>
                        <DotLoading />
                    </>
                ) : (
                    <span>--- 没有更多数据 ---</span>
                )}
            </>
        )
    }

    const [data, setData] = useState<IMyBookingListData[]>([]);
    const [hasMore, setHasMore] = useState(true);
    async function loadMore(isReload: boolean = false) {

        let _page = page
        if (isReload) {
            _page = 1;
        }

        let res = await getMyBookingList({ page: _page, size });


        if (isReload) {
            setData(res.data.list);
        } else {
            setData(val => [...val, ...res.data.list]);
        }

        setHasMore(res.data.list.length === size);
        setPage(_page + 1);

        setIsInitLogin(true);
    }

    const onRefresh = async () => {
        console.log('refresh');
        loadMore(true);
    }

    // 搜索参数
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(20);
    // const [type, setType] = useState<number>();
    // const [keywords, setKeywords] = useState("");

    // 关键词搜索
    // const keywordsDebouncedValue = useDebounce(keywords, { wait: 500 });
    // useEffect(() => {
    //     if (isInitLogin) loadMore(true);
    // }, [keywordsDebouncedValue]);

    return (
        <div className='case'>
            <div className='list'>
                {/* <div className='search'>
                    <Space className='float' direction='vertical'>
                    <SearchBar placeholder='关键词搜索' onChange={setKeywords} />
                    </Space>
                </div> */}

                <List className='content'>
                    <PullToRefresh
                        onRefresh={onRefresh}
                    >
                        {data.map((item, index) => (
                            <Card
                                key={`list${item.id}`}
                                className='card'
                                extra={<span style={{ color: 'gray', fontSize: 14 }}>{item.created_at}</span>}
                            >
                                <div className='card-content'>
                                    <div>预约顾问：{item.adviser ? `${item.adviser.real_name}${item.adviser.level_text ? `(${item.adviser.level_text})` : ''}` : '待安排'}</div>
                                    <div>预约内容：{item.content}</div>
                                    <div>预留电话：{item.mobile}</div>
                                    <div>预留时间：{item.booking_at}</div>
                                </div>
                            </Card>
                        ))}
                    </PullToRefresh >
                </List>

                <InfiniteScroll loadMore={loadMore} hasMore={hasMore}>
                    <InfiniteScrollContent hasMore={hasMore} />
                </InfiniteScroll>
            </div>
        </div>
    );
}

export default MyBookingPage;