
import { useEffect, useState } from 'react';

import { Button, Card, Image, NoticeBar, CenterPopup, Skeleton, Tabs, Toast, Dialog } from 'antd-mobile';
import './pay.scss';
import { getVipLevelList, IVipList, IVipPay, IVipPayType1, IVipPayType2, toVipPay } from '../../apis/vip';
import { getWxConfig } from '../../apis/common';

import wx from 'weixin-js-sdk';
import { useNavigate, useParams } from 'react-router-dom';
import { getProjectList, IProjectData, checkOrder } from '../../apis/project';
import { IUserInfoData } from '../../apis/user';

import help from '../../assets/help.svg';

export function PayPage() {
    document.title = '支付页';
    const { type } = useParams();

    const [isVip, setIsVip] = useState<boolean>();
    const [isProject, setIsProject] = useState<boolean>();

    const [levelList, setLevelList] = useState<IVipList[]>([]);
    const [projectList, setProjectList] = useState<IProjectData[]>([]);

    const [code, setCode] = useState<[number, number]>();
    const [projectId, setProjectId] = useState<number>();

    // 初始化
    useEffect(() => {
        Toast.show({
            icon: 'loading',
            content: '加载中',
            duration: 0,
            maskClickable: false
        });

        let user: IUserInfoData = JSON.parse(sessionStorage.getItem('user')!);
        setUserVip(user.is_vip);

        getVipLevelList().then(res => {
            setLevelList(res.data.level);
            setTabKey(res.data.level[0]?.id.toString());
        })

        if (type === 'vip') {
            setIsVip(true);
        } else if (type === 'project') {
            setIsProject(true);
            getProjectList().then(res => {
                setProjectList(res.data.list);
            })
        }

        getWxConfig().then(({ data }) => {
            wx.config({
                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                appId: data.appId, // 必填，公众号的唯一标识
                timestamp: data.timestamp, // 必填，生成签名的时间戳
                nonceStr: data.nonceStr, // 必填，生成签名的随机串
                signature: data.signature,// 必填，签名
                jsApiList: ["chooseWXPay"] // 必填，需要使用的JS接口列表chooseWXPay为支付js接口
            });
        });
    }, []);

    wx.ready(() => {
        Toast.clear();
    });

    wx.error((err) => {
        Toast.clear();
        console.log(err);
        Toast.show({
            content: 'jssdk初始化失败',
            position: 'bottom',
        })
    });

    const toPay = async () => {
        Toast.show({
            icon: 'loading',
            content: '加载中',
            duration: 0,
            maskClickable: false
        });

        if (isVip && code) {
            try {
                let { data } = await toVipPay({ level: code[0], level_item: code[1], type: 1 });
                payFunc(data);
            } catch (e) {
                console.log(e);
            }
        }

        if (isProject && projectId) {
            try {
                let { data } = await toVipPay({
                    type: 2,
                    vip_project_id: projectId,
                    is_buy_vip: !!code,
                    level: code ? code[0] : undefined,
                    level_item: code ? code[1] : undefined
                });
                payFunc(data);
            } catch (e) {
                console.log(e);
            }
        }

        Toast.clear();
    }

    // 支付执行方法
    const payFunc = (data: IVipPay) => {
        switch (data.pay_type) {
            case 1:
                /**
                 * 微信支付
                 */
                let payment1 = data.payment as IVipPayType1;
                wx.chooseWXPay({
                    timestamp: payment1.timestamp, // 支付签名时间戳，注意微信 jssdk 中的所有使用 timestamp 字段均为小写。但最新版的支付后台生成签名使用的 timeStamp 字段名需大写其中的 S 字符
                    nonceStr: payment1.nonceStr, // 支付签名随机串，不长于 32 位
                    package: payment1.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
                    signType: payment1.signType, // 微信支付V3的传入 RSA ,微信支付V2的传入格式与V2统一下单的签名格式保持一致
                    paySign: payment1.paySign, // 支付签名
                    success: (res) => {
                        console.log(res);
                        // if (res.errMsg === 'chooseWXPay:ok') {
                        // 支付成功后的回调函数
                        Toast.show({
                            icon: 'success',
                            content: '支付成功请稍后',
                            duration: 5000,
                            afterClose: () => navigate('/', { replace: true }),
                            maskClickable: false
                        });
                        // }
                    }
                });
                break;
            case 2:
                /**
                 * 二维码支付
                 */
                let payment2 = data.payment as IVipPayType2;
                setPayQRcode(payment2.offline_pay_url);
                setPayQRcodeVisible(true);
                break;

            default:
                Toast.show({
                    icon: 'fail',
                    content: '支付响应参数错误',
                    duration: 5000,
                    maskClickable: false
                })
                break;
        }
    }

    const [tabKey, setTabKey] = useState<string>()
    const onTabChange = (key: string) => {
        setCode(undefined);
        setTabKey(key);
    }

    useEffect(() => {
        if (isProject && projectId) {
            Toast.show({
                icon: 'loading',
                content: '加载中',
                duration: 0,
                maskClickable: false
            });

            let func;
            if (code) {
                func = checkOrder({ type: 2, vip_project_id: projectId, level: code[0], level_item: code[1], is_buy_vip: true }).then(res => {
                    setUserVip(res.data.is_vip);
                    setMoney(res.data.money);
                    setLevelList(res.data.level_list);
                });
            } else {
                func = checkOrder({ type: 2, vip_project_id: projectId, is_buy_vip: false })
            }
            func.then(res => {
                if (res) {
                    setUserVip(res.data.is_vip);
                    setMoney(res.data.money);
                    setLevelList(res.data.level_list);
                }
            }).finally(() => {
                Toast.clear();
            })
        }
    }, [code, projectId])

    const [userVip, setUserVip] = useState<boolean>();
    const [money, setMoney] = useState<number>();

    const onProjectSelected = async (id: number) => {
        setProjectId(id);
    }

    const onVipSelected = (input_code: [number, number]) => {
        if (isProject && code && (input_code[0] == code[0] && input_code[1] == code[1])) {
            setCode(undefined);
        } else {
            setCode(input_code);
        }
    }

    const navigate = useNavigate();

    // 支付二维码弹出
    const [payQRcodeVisible, setPayQRcodeVisible] = useState(false);
    const [payQRcode, setPayQRcode] = useState<string>();
    const paid = async () => {
        const result = await Dialog.confirm({
            content: '确定已经支付？即将跳转回首页。',
            cancelText: '还未支付'
        })
        if (result) {
            setPayQRcodeVisible(false);
            navigate('/', { replace: true });
        }
    }

    return (
        <div className='pay'>
            {
                isVip ?
                    levelList ?
                        <Tabs activeKey={tabKey} onChange={onTabChange}>
                            {
                                levelList.map((vip) => (
                                    <Tabs.Tab title={vip.name} key={vip.id}>
                                        <div className='selector'>
                                            {
                                                vip.list.map((item) => (
                                                    <div className={`item ${code && item.id === code[1] ? 'active' : ''}`} onClick={() => setCode([vip.id, item.id])}>
                                                        <div className='time'>{item.duration}{item.duration_type_text}</div>
                                                        <div className='price'>
                                                            <div>￥<span>{item.price}</span></div>
                                                        </div>
                                                        <div className='discount'>{item.title}</div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </Tabs.Tab>
                                ))
                            }
                        </Tabs>
                        : <Skeleton animated style={{ width: '100%', height: 100 }} />
                    : <></>
            }
            {
                isProject ?
                    projectList ?
                        <>
                            <div className='selector' style={{ padding: '20px 10px 0 10px' }}>
                                {
                                    projectList.map(item => (
                                        <div className={`item ${projectId && item.id === projectId ? 'active' : ''}`} onClick={() => onProjectSelected(item.id)}>
                                            {item.label && <div className='title'>{item.label}</div>}
                                            <div className='time'>{item.title}</div>
                                            <div className='price'>
                                                <div>￥<span>{item.vip_price}</span></div>
                                                <div>￥{item.price}</div>
                                            </div>
                                            {/* <div className='month'>￥18.17/月</div> */}
                                            <div className='discount'>会员优惠{item.discount}%</div>
                                        </div>
                                    ))
                                }
                            </div>
                            <Card title='项目介绍'>
                                <div style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: projectId ? (projectList.find(item => item.id === projectId)?.content ?? '暂无介绍') : '请选择要购买的项目' }}></div>
                            </Card>
                        </>
                        : <Skeleton animated style={{ width: '100%', height: 100 }} />
                    : <></>
            }

            {
                isProject && !userVip
                    ? <NoticeBar
                        style={{ marginTop: 15 }}
                        color='info'
                        content={'开通vip即可享受会员折扣价'}
                        extra={
                            <img
                                style={{ width: 20, height: 20 }}
                                src={help}
                                alt=""
                                onClick={() => navigate('/vip/')}
                            />
                        }
                    />
                    : <></>
            }

            {
                isProject && !userVip ?
                    levelList ?
                        <Tabs activeKey={tabKey} onChange={onTabChange}>
                            {
                                levelList.map((vip) => (
                                    <Tabs.Tab title={vip.name} key={vip.id}>
                                        <div className='selector'>
                                            {
                                                vip.list.map((item) => (
                                                    <div className={`item small ${code && item.id === code[1] ? 'active' : ''}`} onClick={() => onVipSelected([vip.id, item.id])}>
                                                        <div className='time'>{item.duration}{item.duration_type_text}</div>
                                                        <div className='price'>
                                                            <div>￥<span>{item.price}</span></div>
                                                        </div>
                                                        <div className='discount'>{item.title}</div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </Tabs.Tab>
                                ))
                            }
                        </Tabs>
                        : <Skeleton animated style={{ width: '100%', height: 100 }} />
                    : <></>
            }

            <Button className='button' block color='primary' size='large' disabled={isVip ? (!code) : (!projectId)} onClick={toPay}>
                立即支付 {
                    money ? ` ${money}元` : ''
                }
            </Button>

            {/* 支付二维码弹出 */}
            <CenterPopup
                visible={payQRcodeVisible}
                closeOnMaskClick={false}
                showCloseButton={true}
                onClose={() => {
                    setPayQRcodeVisible(false)
                }}
                bodyStyle={{ height: '80vh' }}
            >
                <div className='qrcode-box'>
                    <Image className='qrcode-box__img' src={payQRcode} />
                    <Button className='qrcode-box__btn' color='primary' onClick={paid}>我已支付</Button>
                </div>

            </CenterPopup>
        </div>
    );
}