import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUserInfo, IUserInfoData, login } from '../../apis/user';
import { Button, Card, Dialog, DotLoading, FloatingBubble, InfiniteScroll, List, Modal, PullToRefresh, SearchBar, Space, TabBar, Tabs, Toast } from 'antd-mobile';

import {
    AppOutline,
    UserOutline,
    MessageFill
} from 'antd-mobile-icons';

import './case.scss';
import { getCaseList, getCaseListen, getMyAskBalance, getMyListenBalance, getMyQuestionReplyList, ICaseListData } from '../../apis/case';
import { useDebounce } from 'ahooks';
import { getDictList, IDictDataOption } from '../../apis/common';

function CasePage() {
    document.title = '主页';

    const navigate = useNavigate();

    const [isInitLogin, setIsInitLogin] = useState(false);

    // 用户信息
    const [userInfo, setUserInfo] = useState<IUserInfoData>();

    // 获取用户信息
    const initUserInfo = async () => {
        console.log('========获取用户信息');

        let userInfoRes = await getUserInfo();
        if (userInfoRes) {
            let userInfo = userInfoRes.data.info;
            // 是否有用户信息
            if (userInfo.is_auth) {
                sessionStorage.setItem('user', JSON.stringify(userInfo));
                setUserInfo(userInfo);
                if (!userInfo.is_customer) {
                    // Dialog.confirm({
                    //     content: '前往完善会员信息',
                    //     onConfirm: () => {
                    //         navigate('/info/');
                    //     },
                    // });
                    navigate('/info/');
                } else {
                    // 查询是否有回复消息
                    getMyQuestionReplyList().then(res => {
                        if (res.data.list.length > 0) {
                            let handler = Modal.show({
                                content: (
                                    <List header='回复消息提醒'>
                                        {
                                            res.data.list.map(ques => (
                                                <List.Item onClick={() => {
                                                    handler.close();
                                                    navigate(`/questionDetail?id=${ques.id}`);
                                                }}>
                                                    {ques.content.substring(0, 7)}... 已回复
                                                </List.Item>
                                            ))
                                        }
                                    </List>
                                ),
                                closeOnMaskClick: true,
                            });
                        }
                    }
                    );
                }
            } else {
                getInfoDialog();
            }
        }

        Toast.clear();
    }

    // 初始化
    useEffect(() => {
        Toast.show({
            icon: 'loading',
            content: '加载中',
            duration: 0,
            maskClickable: false
        });

        let token = sessionStorage.getItem('token');
        if (!token) {
            let code = getUrlKeyVal('code');
            if (code) {
                if (getUrlKeyVal('test')) {
                    Toast.clear();
                    return;
                }
                // 换取token
                let scope = getUrlKeyVal('state') ?? '';
                let agentId = getUrlKeyVal('agent_id');
                login({ code, scope, agent_id: agentId }).then(async (res) => {
                    // 存储token
                    sessionStorage.setItem('token', res.data.token);
                    setIsInitLogin(true);

                    initUserInfo();
                })
            } else {
                // 静默授权
                getWeChatCode('snsapi_base');
            }
        } else {
            initUserInfo();
            setIsInitLogin(true);
        }
    }, []);

    // 请求通过微信官方接口 获取code
    function getWeChatCode(scope = 'snsapi_userinfo') {
        if (scope === 'snsapi_userinfo') sessionStorage.removeItem('token');
        // 获取当前页面地址作为回调地址
        let local = encodeURIComponent(getUrlKeyVal('code') ? window.location.origin : window.location.href);
        console.log(local);
        let appid = 'wxf64fab5b9ecdfc50';// 自己填入测试号信息
        // 访问设置的回调地址 redirect_uri，会重定向显示带code参数的地址
        window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + appid +
            "&redirect_uri=" + local +
            `&response_type=code&scope=${scope}&state=${scope.split('_')[1]}#wechat_redirect`;
    }

    // 提取地址中的参数值
    function getUrlKeyVal(key: string) {
        let r = new RegExp(`[?|&]${key}=([^&;]+?)(&|#|;|$)`);
        let arr = r.exec(window.location.href) ?? [undefined, ''];
        let s = arr[1]!.replace(/\+/g, '%20');
        return decodeURIComponent(s) || undefined;
    }

    // 获取snsapi_userinfo授权
    const getInfoCode = () => {
        getWeChatCode();
    }

    const getInfoDialog = () => {
        Dialog.show({
            content: '授权您的基础信息，将更好的为您提供服务',
            closeOnAction: true,
            actions: [
                [
                    {
                        key: 'cancel',
                        text: '取消',
                    },
                    {
                        key: 'ok',
                        text: '授权',
                        bold: true,
                        danger: true
                    },
                ],
            ],
            onAction: (action, index) => {
                if (action.key === 'ok') {
                    getWeChatCode();
                }
            }
        });
    }


    const loginCheck = (): boolean => {
        if (!userInfo?.is_auth) {
            getInfoDialog();
            return false;
        }

        if (!userInfo.is_customer) {
            Dialog.show({
                content: '请先完善您的信息',
                closeOnAction: true,
                actions: [
                    [
                        {
                            key: 'cancel',
                            text: '取消',
                        },
                        {
                            key: 'ok',
                            text: '确定',
                            bold: true,
                            danger: true
                        },
                    ],
                ],
                onAction: (action, index) => {
                    if (action.key === 'ok') {
                        navigate('/info/');
                    }
                }
            });
            return false;
        }

        return true;
    }

    const toQuestion = async () => {
        if (loginCheck()) {
            // 检查余额
            let res = await getMyAskBalance();
            if (res.data.balance > 0) {
                navigate('/question/');
            } else {
                Dialog.confirm({
                    content: '你的提问次数不足，是否前往购买？',
                    onConfirm: () => {
                        navigate('/case/pay?type=2');
                    },
                });
            }
        }
    }

    const toQuestionDetail = async (id: number) => {
        const nav = () => {
            navigate(`/questionDetail?id=${id}`);
        }

        if (loginCheck()) {
            // 检查是否可直接进入
            let checkRes = await getCaseListen({ id });
            if (checkRes.data.is_listen) {
                nav();
                return;
            }

            // 检查余额
            let res = await getMyListenBalance();
            if (res.data.balance > 0) {
                nav();
            } else {
                Dialog.confirm({
                    content: '你的旁听次数不足，是否前往购买？',
                    onConfirm: () => {
                        navigate('/case/pay?type=1');
                    },
                });
            }
        }
    }

    // 标签栏
    const tabs = [
        {
            key: 'home',
            title: '首页',
            icon: <AppOutline />,
        },
        {
            key: 'mine',
            title: '我的',
            icon: <UserOutline />,
        },
    ]
    const onTabBarChange = (key: string) => {
        switch (key) {
            case 'mine':
                navigate('/mine/', { replace: true });
                break;

            default:
                break;
        }
    }

    const InfiniteScrollContent = ({ hasMore }: { hasMore?: boolean }) => {
        return (
            <>
                {hasMore ? (
                    <>
                        <span>加载中</span>
                        <DotLoading />
                    </>
                ) : (
                    <span>--- 没有更多数据 ---</span>
                )}
            </>
        )
    }

    const [data, setData] = useState<ICaseListData[]>([]);
    const [hasMore, setHasMore] = useState(true);
    async function loadMore(isReload: boolean = false) {
        if (!isInitLogin) return;

        let _page = page
        if (isReload) {
            _page = 1;
        }

        let res = await getCaseList({ page: _page, size, type_id: type, keywords });

        if (isReload) {
            setData(res.data.list);
        } else {
            setData(val => [...val, ...res.data.list]);
        }

        setHasMore(res.data.list.length === size);
        setPage(_page + 1);
    }

    const onRefresh = async () => {
        console.log('refresh');
        loadMore(true);
    }

    // 搜索参数
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(20);
    const [type, setType] = useState<number>();
    const [keywords, setKeywords] = useState("");

    // 关键词搜索
    const keywordsDebouncedValue = useDebounce(keywords, { wait: 500 });
    useEffect(() => {
        loadMore(true);
    }, [keywordsDebouncedValue]);

    // 类型筛选
    const [typeList, setTypeList] = useState<IDictDataOption[]>([]);
    useEffect(() => {
        if (!isInitLogin) return;
        getDictList(['vip_question_type']).then((res) => {
            setTypeList(res.data.vip_question_type!.options);
        });
    }, [isInitLogin]);
    const onTabsChange = (key: string) => {
        console.log(key);
        if (key === '0') {
            setType(undefined);
        } else {
            setType(Number(key));
        }
    }
    useEffect(() => {
        loadMore(true);
    }, [type]);

    return (
        <div className='case'>
            <div className='list'>
                <div className='search'>
                    <Space className='float' direction='vertical'>
                        <SearchBar placeholder='关键词搜索' onChange={setKeywords} />
                        <Tabs defaultActiveKey='0' onChange={onTabsChange}>
                            <Tabs.Tab title='全部' key='0' />
                            {
                                typeList.map(item => <Tabs.Tab title={item.name} key={item.value} />)
                            }
                        </Tabs>
                    </Space>
                </div>
                <List className='content'>
                    <PullToRefresh
                        onRefresh={onRefresh}
                    >
                        {data.map((item, index) => (
                            <Card
                                key={`list${item.id}`}
                                className='card'
                                extra={<span style={{ color: 'gray', fontSize: 14 }}>{item.created_at}</span>}
                                title={
                                    <div className='card-header'>
                                        <img className='avatar' src={item.oauth_user.headimgurl} alt='avatar' />
                                        {item.oauth_user.nickname}
                                    </div>
                                }
                            >
                                <div className='card-content'>{item.content}</div>
                                {
                                    item.is_reply &&
                                    <div className='card-footer' onClick={e => e.stopPropagation()}>
                                        <Button
                                            size='small'
                                            shape='rounded'
                                            color='primary'
                                            onClick={() => {
                                                toQuestionDetail(item.id);
                                            }}
                                        >
                                            旁听
                                        </Button>
                                    </div>
                                }
                            </Card>
                        ))}
                    </PullToRefresh >
                </List>
                {
                    isInitLogin &&
                    <InfiniteScroll loadMore={loadMore} hasMore={hasMore}>
                        <InfiniteScrollContent hasMore={hasMore} />
                    </InfiniteScroll>
                }

                <FloatingBubble
                    axis='xy'
                    magnetic='x'
                    style={{
                        '--initial-position-bottom': '60px',
                        '--initial-position-right': '15px',
                        '--edge-distance': '90px 15px 60px 15px',
                        '--size': '55px'
                    }}
                    onClick={toQuestion}
                >
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <MessageFill fontSize={26} />
                        <div style={{ fontSize: 10 }}>我要提问</div>
                    </div>
                </FloatingBubble>
            </div>

            <div className='bottom'>
                <TabBar className='tabBar' safeArea activeKey="home" onChange={onTabBarChange}>
                    {tabs.map(item => (
                        <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
                    ))}
                </TabBar>
            </div>
        </div>
    );
}

export default CasePage;