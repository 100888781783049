import { useEffect, useRef, useState } from 'react';

import { Button, DotLoading, Toast } from 'antd-mobile';

import './vip.scss';

import logo from '../../assets/logo.svg';
import wave from '../../assets/wave.svg';
import wave2 from '../../assets/wave2.svg';

import { getVipIntroList, IVipIntro } from '../../apis/vip';
import { IUserInfoData } from '../../apis/user';
import { useNavigate, useSearchParams } from 'react-router-dom';

function VipPage() {
    document.title = '会员介绍';

    const navigate = useNavigate();

    const [uRLSearchParams, setURLSearchParams] = useSearchParams();

    const bottomLine = useRef<HTMLDivElement>(null);

    const scrollToBottom = () => {
        if (bottomLine && bottomLine.current) {
            bottomLine.current.scrollIntoView({
                behavior: "smooth"
            });
        }
    }

    const [dataList, setDataList] = useState<IVipIntro[]>();
    const [user, setUser] = useState<IUserInfoData>();

    // 初始化
    useEffect(() => {
        Toast.show({
            icon: 'loading',
            content: '加载中',
            duration: 0,
            maskClickable: false
        });

        setUser(JSON.parse(sessionStorage.getItem('user')!));

        getVipIntroList().then(res => {
            setDataList(res.data.list);
            Toast.clear();
        })

    }, []);

    const toLevel = () => {
        navigate('/pay/vip');
    }

    return (
        <div className='vip'>
            <div className='top-box'>
                <img className='wave' src={wave} alt='' />
                <img className='wave' src={wave2} alt='' />
                <div className='top-box__inner'>
                    <div onClick={scrollToBottom}>
                        <div style={{ fontSize: 18 }}>{user?.is_vip ? '续费' : '开通'}会员</div>
                        <div style={{ fontSize: 12 }}>会员可享受以下特权</div>
                    </div>
                    <img className='logo' src={logo} alt='logo' />
                </div>
            </div>
            <div className='bottom-box'>
                <div className='function-box'>
                    <h2>VIP特权</h2>
                    {
                        dataList
                            ? dataList.map((item) => (
                                <div className='func-item' key={item.id}>
                                    <img src={item.attach_icon_link} alt='' />
                                    <div>
                                        <div style={{ fontWeight: 'bold' }}>{item.title}</div>
                                        <div style={{ fontSize: 12, color: 'grey' }}>{item.intro}</div>
                                    </div>
                                </div>
                            ))
                            : <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <DotLoading />
                            </div>
                    }
                    {
                        uRLSearchParams.get('nav')
                            ? <Button block color='primary' size='middle' style={{ marginTop: 20 }} onClick={toLevel}>
                                {user?.is_vip ? '续费' : '开通'}会员
                            </Button>
                            : <></>
                    }
                </div>
            </div>

            {/* 用于滚动到底部 */}
            <div ref={bottomLine}></div>
        </div>
    );
}

export default VipPage;