import { axiosInstance, CommonResponse } from ".";


export interface IVipPayType1 {
    nonceStr: string,
    package: string,
    signType: string,
    paySign: string,
    timestamp: number,
}

export interface IVipPayType2 {
    offline_pay_url: string
}
export interface IVipPay {
    payment: IVipPayType1 | IVipPayType2,
    pay_type: 1 | 2
}

interface IVipPayForm {
    type: 1 | 2,
    level?: number,
    level_item?: number,
    is_buy_vip?: boolean,
    vip_project_id?: number
}

/**
 * @description: 购买vip
 * @returns {Promise}
 */
export const toVipPay = (data: IVipPayForm): Promise<CommonResponse<IVipPay>> => {
    return axiosInstance.post('/vip/order/pay', data).then(res => res.data);
};

export interface IVipIntro {
    id: number,
    title: string,
    intro: string,
    attach_icon: number,
    attach_icon_link: string
}

interface IVipIntroRes {
    list: IVipIntro[]
}

/**
 * @description: 获取vip特权列表
 * @returns {Promise}
 */
export const getVipIntroList = (): Promise<CommonResponse<IVipIntroRes>> => {
    return axiosInstance.get('/vip/privilege/index').then(res => res.data);
}

export interface IVipLevel {
    title: string,
    price: number,
    duration: number,
    duration_type: number,
    duration_type_text: string,
    id: number
}

export interface IVipList {
    id: number,
    name: string,
    list: IVipLevel[]
}

interface IVipLevelRes {
    level: IVipList[],
}

/**
 * @description: 获取vip购买列表
 * @returns {Promise}
 */
export const getVipLevelList = (): Promise<CommonResponse<IVipLevelRes>> => {
    return axiosInstance.get('/vip/config/level').then(res => res.data);
}

