import { axiosInstance, CommonResponse } from ".";
import { IVipPay } from "./vip";

export interface IAdviserListDataRegion {
    vip_adviser_id: number,
    province_id: number,
    city_id: number,
    area_id: number,
    province_text: string,
    city_text: string,
    area_text: string
}

export interface IAdviserListData {
    id: number,
    real_name: string,
    avatar_id: number,
    expertise: string,
    avatar_link: string,
    sex_text?: string,
    level_text?: string,
    region: IAdviserListDataRegion[],
}


interface IAdviserList {
    list: IAdviserListData[]
}

interface IAdviserListSearchForm {
    page: number,
    size: number,
    keywords?: string
}

/**
 * @description: 顾问所有列表
 * @returns {Promise}
 */
export const getAdviserList = (data: IAdviserListSearchForm): Promise<CommonResponse<IAdviserList>> => {
    return axiosInstance.post('/vip/adviser/list', data).then(res => res.data);
}


export interface IAdviserDetailData {
    id: number,
    real_name: string,
    avatar_id: number,
    level_id: number,
    sex: number,
    expertise: string,
    info: string,
    avatar_link: string,
    sex_text: string,
    level_text: string,
    region: IAdviserListDataRegion[]
}

interface IAdviserDetail {
    detail: IAdviserDetailData
}


/**
 * @description: 顾问详情
 * @returns {Promise}
 */
export const getAdviserDetail = (data: { id: number }): Promise<CommonResponse<IAdviserDetail>> => {
    return axiosInstance.post('/vip/adviser/detail', data).then(res => res.data);
}

export interface IReservationDetail {
    money: number,
    title: string,
    service_intro: string
}


/**
 * @description: 预约页面详情
 * @returns {Promise}
 */
export const getReservationDetail = (): Promise<CommonResponse<IReservationDetail>> => {
    return axiosInstance.post('/vip/adviser/check_booking').then(res => res.data);
}



export interface IAdviserForm {
    adviser_id?: number,
    real_name: string,
    mobile: string,
    booking_at: string,
    time_period?: number,
    can_adjust: boolean,
    content: string
}

/**
 * @description: 顾问预约
 * @returns {Promise}
 */
export const toReservation = (data: IAdviserForm): Promise<CommonResponse<IVipPay>> => {
    return axiosInstance.post('/vip/adviser/booking', data).then(res => res.data);
}


export interface IMyBookingListData {
    id: number,
    real_name?: string,
    vip_adviser_id: number,
    mobile?: string,
    booking_at: string,
    created_at: string,
    time_period: number,
    can_adjust: false,
    is_set_plan: false,
    content: string,
    adviser?: {
        id: number,
        real_name: string,
        avatar_id: number,
        sex: number,
        level_id: number,
        expertise: string,
        avatar_link: string,
        sex_text: string,
        level_text: string
    }
}


interface IMyBookingList {
    list: IMyBookingListData[]
}

interface IMyBookingListSearchForm {
    page: number,
    size: number,
    // keywords?: string
}

/**
 * @description: 顾问我的预约列表
 * @returns {Promise}
 */
export const getMyBookingList = (data: IMyBookingListSearchForm): Promise<CommonResponse<IMyBookingList>> => {
    return axiosInstance.post('/vip/adviser/my_booking', data).then(res => res.data);
}
