import { axiosInstance, CommonResponse } from ".";
import { IVipLevel } from "./vip";


export interface IProjectData {
    id: number,
    title: string,
    sub_title: string,
    vip_price: number,
    price: number,
    buy_type: number,
    expired_date?: string,
    num_type: number,
    num: number,
    label?: string,
    duration_text: string,
    discount: number,
    content?: string,
}


interface IProjectRes {
    list: IProjectData[]
}

/**
 * @description: 获取项目购买列表
 * @returns {Promise}
 */
export const getProjectList = (): Promise<CommonResponse<IProjectRes>> => {
    return axiosInstance.get('/vip/project/index').then(res => res.data);
}

interface ICheckOrderForm {
    type: 2,
    vip_project_id: number,
    level?: number,
    level_item?: number,
    is_buy_vip: boolean
}

export interface IOrderCheckLevel {
    id: number,
    name: string,
    list: IVipLevel[]
}

interface IOrderCheckRes {
    level_list: IOrderCheckLevel[],
    is_vip: boolean,
    money: number
}

/**
 * @description: 获取购买项目数据
 * @returns {Promise}
 */
export const checkOrder = (data: ICheckOrderForm): Promise<CommonResponse<IOrderCheckRes>> => {
    return axiosInstance.post('/vip/order/check', data).then(res => res.data);
}

