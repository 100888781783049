import { axiosInstance, CommonResponse } from ".";

export interface ILoginForm {
    code: string,
    scope: string,
    agent_id?: string
}

export interface ILoginData {
    token: string,
    openid: string
}

/**
 * @description: 用户登录
 * @params {ILoginForm} params
 * @return {Promise}
 */
export const login = (params: ILoginForm): Promise<CommonResponse<ILoginData>> => {
    return axiosInstance.get('/vip/oauth/login', { params }).then(res => res.data);
};

interface IUserInfoRes {
    info: IUserInfoData
}

export interface IUserInfoData {
    id: number,
    openid: string,
    nickname: string,
    headimgurl: string,
    is_customer: boolean,
    is_vip: boolean,
    vip_expired_date: string,
    is_auth: false
}

/**
 * @description: 获取用户信息
 * @returns {Promise}
 */
export const getUserInfo = (): Promise<CommonResponse<IUserInfoRes>> => {
    return axiosInstance.get('/vip/oauth/user_info').then(res => res.data);
};


