import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Image, DotLoading, InfiniteScroll, List, PullToRefresh, SearchBar, Space, TabBar, Tabs, Toast, Ellipsis } from 'antd-mobile';

import { getAdviserList, IAdviserListData } from '../../apis/adviser';

import "./adviser.scss";
import { useDebounce } from 'ahooks';

function AdviserPage() {
    document.title = '顾问列表';

    const navigate = useNavigate();

    const InfiniteScrollContent = ({ hasMore }: { hasMore?: boolean }) => {
        return (
            <>
                {hasMore ? (
                    <>
                        <span>加载中</span>
                        <DotLoading />
                    </>
                ) : (
                    <span>--- 没有更多数据 ---</span>
                )}
            </>
        )
    }

    const [isInitLogin, setIsInitLogin] = useState(false);

    // 搜索参数
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(20);
    const [keywords, setKeywords] = useState("");

    // 关键词搜索
    const keywordsDebouncedValue = useDebounce(keywords, { wait: 500 });
    useEffect(() => {
        if (isInitLogin) loadMore(true);
    }, [keywordsDebouncedValue]);


    const [data, setData] = useState<IAdviserListData[]>([]);
    const [hasMore, setHasMore] = useState(true);
    async function loadMore(isReload: boolean = false) {
        Toast.show({
            icon: 'loading',
            content: '加载中',
            duration: 0,
            maskClickable: false
        });

        let _page = page;
        if (isReload) {
            _page = 1;
        }

        try {
            let res = await getAdviserList({ page: _page, size, keywords });

            if (isReload) {
                setData(res.data.list);
            } else {
                setData(val => [...val, ...res.data.list]);
            }

            setHasMore(res.data.list.length === size);
            setPage(_page + 1);
        } catch (e) {
            throw new Error('请求失败');
        } finally {
            Toast.clear();
        }

        setIsInitLogin(true);
    }

    const onRefresh = async () => {
        console.log('refresh');
        loadMore(true);
    }

    return (
        <div className='adviser'>
            <div className='search'>
                <Space direction='vertical' style={{ width: '100%' }}>
                    <SearchBar placeholder='关键词搜索' onChange={setKeywords} />
                </Space>
            </div>
            <List className='content'>
                <PullToRefresh
                    onRefresh={onRefresh}
                >
                    {data.map((item, index) => (
                        <List.Item
                            key={item.avatar_id}
                            prefix={
                                <Image
                                    src={item.avatar_link}
                                    style={{ borderRadius: 20, marginTop: 15 }}
                                    fit='cover'
                                    width={40}
                                    height={40}
                                />
                            }
                            description={<div>
                                <div>{item.region.map(e => e.area_text).join('，')}</div>
                                <Ellipsis style={{ color: 'black', fontSize: 14 }} direction='end' rows={2} content={item.expertise ?? '暂无'} />
                            </div>}
                            onClick={() => { navigate(`/adviser/detail?id=${item.id}`) }}
                        >
                            {item.real_name} {item.level_text && `(${item.level_text})`}
                        </List.Item>
                    ))}
                </PullToRefresh >
            </List>
            <InfiniteScroll loadMore={loadMore} hasMore={hasMore}>
                <InfiniteScrollContent hasMore={hasMore} />
            </InfiniteScroll>
        </div>
    );
}

export default AdviserPage;